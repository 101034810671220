import { createI18n } from 'vue-i18n'

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
// function loadLocaleMessages() {
//   const locales = require.context('./locale', true, /[A-Za-z0-9-_,\s]+\.json$/i)
//   const messages = {}
//   locales.keys().forEach(key => {
//     const matched = key.match(/([A-Za-z0-9-_]+)\./i)
//     if (matched && matched.length > 1) {
//       const locale = matched[1]
//       messages[locale] = locales(key).default
//     }
//   })
//   return messages
// }
const messages = {
  ru: {
    "download":"Скачать",
    "web-version":"Веб версия",
    "good-parent-label":"Электронный дневник для заботливых родителей",
    "help-your-child":"Помогите вашему ребенку достигать новых результатов удобно и просто. Знания, результаты, выбор профессии - все у вас на ладони.",
    "all-education":"Все знания на отлично",
    "what-for-quarter":"Что у нас за четверть?",
    "homework":"Что задавали на дом?",
    "What-to-become":"Кем стать?",
    "all-mark":"Каждая выставленная вашему ребенку оценка появится у вас на экране. Мгновенно. Cвоевременно.",
    "all-quarter-mark":"Полная выписка оценок вашего любимого ученика за четверть. Всё под контролем.",
    "homework-in-app":"Домашние задания в удобном виде. Готовиться стало легко и удобно. Ни одну тему не пропустим.",
    "help-to-choice":"Точно знаем, куда поступать и к чему готовиться в новом учебном году. Помощь с выбором пути.",
    "help-your-child-now":"Помогите ребенку в учебе. Прямо сейчас",
    "we-do-app":"Мы сделали нашу программу понятной и удобной даже для очень занятых бизнеследи и ответственных руководителей. Мы знаем, как важно помочь ребенку получить необходимые знания вовремя. Ведь мы сами родители самых любимых детей.",
    "dowload-for-your-app":"Скачать приложение для вашей платформы:",
    "contacts":["Контакты","Скачать","Возможности","Отзывы","Условия использования"]
  },
  kz: {
    "download":"Жүктеу",
    "web-version":"Веб версия",
    "good-parent-label":"Қамқор ата-аналарға арналған электронды күнделік",
    "help-your-child":"Балаңызға ыңғайлы және қарапайым жаңа нәтижелерге қол жеткізуге көмектесіңіз. Білім, нәтижелер, мамандық таңдау-бәрі сіздің қолыңызда.",
    "all-education":"Барлық білім керемет",
    "what-for-quarter":"Қандай тоқсан?",
    "homework":"Үйге не берді?",
    "What-to-become":"Кім боламың?",
    "all-mark":"Сіздің балаңызға қойылған әрбір баға экранда пайда болады. Бірден. Уақытылы.",
    "all-quarter-mark":"Сіздің сүйікті оқушыңыздың тоқсан бойынша бағаларының толық көшірмесі. Барлығы бақылауда.",
    "homework-in-app":"Үй тапсырмасы ыңғайлы түрде. Дайындалу оңай, әрі ыңғайлы. Бірде-бір тақырыпты жіберіп алмаймыз.",
    "help-to-choice":"Біз жаңа оқу жылында қайда бару керектігін және неге дайындалу керектігін нақты білеміз. Жолыңызды таңдауға көмек.",
    "help-your-child-now":"Балаға оқуға көмектесіңіз. Дәл қазір",
    "we-do-app":"Біз өз бағдарламамызды бос емес бизнес ханымдар мен жауапты менеджерлер үшін де түсінікті және ыңғайлы етіп жасадық. Балаға уақытында қажетті білім алуға көмектесу қаншалықты маңызды екенін білеміз. Өйткені, біз ең сүйікті балалардың ата-анасымыз.",
    "dowload-for-your-app":"Сіздің платформаңыз үшін қосымшаны жүктеп алыңыз:",
    "contacts":["Байланыстар","Жүктеу","Мүмкіндіктер","Пікірлер","Пайдалану шарттары"]
  }
}
export default createI18n({
  legacy: false,
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: messages,
  globalInjection: true
})
