<template>  
  <div>
     <div id="app" class="locale-changer">
		<section class="header clearfix">
			<header class="wrap cl">
				<div class="logo left">
					<a>
						<img src="./images/logo.png">
					</a>
				</div>
				<nav class="nav right">
				<input type="checkbox" id="nav" /><label for="nav"></label>
					<ul>
						<li><a class="link download" href="#">{{$t('download')}}</a></li>
						<li><a class="link" href="https://web.edumark.kz">{{$t('web-version')}}</a></li>
						<li class="d-flex language">
							<span v-for="(item,index) in locales" :key="index" @click="changeLang(item)" :class="{active:lang==item}" class="lang">{{item.toUpperCase()}}</span>
						</li> 
					</ul>
				</nav>
			</header>
		</section>
		<section class="main">
			<div class="block">
				<h2 class="title">{{$t('good-parent-label')}}</h2>
				<div class="text">{{$t("help-your-child")}}</div>
				<div class="apps">
					<a class="link" href="#">
						<img src="./images/app-store-android.png">
					</a>
					<a class="link" href="#">
						<img src="./images/app-store-download.png">
					</a>
				</div>
			</div>
		</section>
		<section class="info wrap">
			<div class="advertisement">
				<img class="phone" src="./images/phone.png">
				<div class="photo-gallery">
				<div id="slider">
					<figure>
						<div>
						<div>
							
						</div>
							<img src="./images/1.png">
							<div class="image-desc">{{$t("all-education")}}</div>
						</div>
						<div class="slide" data-item="0" id="0">
							<div>
								<img src="./images/1.png">
								<div class="image-desc">{{$t("all-education")}}</div>
							</div>
							
						</div>
						<div class="slide" data-item="1" id="1">
							<div>
								<img src="./images/2.png">
								<div class="image-desc">{{$t('what-for-quarter')}}</div>
							</div>
						</div>
						<div class="slide" data-item="2" id="2">
						<div>
							<img src="./images/3.png">
							<div class="image-desc">{{$t("homework")}}</div>
						</div>
						</div>
						<div class="slide" data-item="3" id="3">
						<div>
							<img src="./images/4.png">
							<div class="image-desc">{{$t("What-to-become")}}</div>
						</div>
						</div>
						
					</figure>
				</div>
			</div>
			</div>
			
			<div class="left-inform" >
				<div class="container js-hover">
					<div class="info-title">{{$t('all-education')}}</div>
					<div class="info-text" id="0">{{$t('all-mark')}}</div>
				</div>
				<div class="container js-hover">
					<div class="info-title">{{$t('what-for-quarter')}}</div>
					<div class="info-text" id="1">{{$t('all-quarter-mark')}}</div>
				</div>
			</div>
	
			<div class="right-inform">
				<div class="container js-hover">
					<div class="info-title">{{$t("homework")}}</div>
					<div class="info-text" id="2">{{$t('homework-in-app')}}</div>
				</div>
				<div class="container js-hover">
					<div class="info-title">{{$t('What-to-become')}}</div>
					<div class="info-text" id="3">{{$t('help-to-choice')}}</div>
				</div>
			</div>
		</section>
		<section class="helper">
		<div class="video">
			<div class="videoWrapper">
				<img src="./images/5.png">
			</div>
		</div>
		<div class="container">
			<h3 class="help-title">{{$t('help-your-child-now')}}</h3>
			<div class="help-text">
				{{$t('we-do-app')}}
			</div>
			<div class="download-app">{{$t('dowload-for-your-app') }}</div>
			<div class="apps">
				<a class="dload" href="#"><img src="./images/apple.png"></a>
				<a class="dload" href="#"><img src="./images/play-m.png"></a>
			</div>
			</div>
		</section>
		<section class="footer">
		<div class="footer-nav" v-for="(item,index) in contacts()" :key="index">
			<a class="link" href="#">{{item}}</a>
			<!-- <a class="link" href="#">Скачать</a>
			<a class="link" href="#">Возможности</a>
			<a class="link" href="#">Отзывы</a>
			<a class="link" href="#">Условия использования</a> -->
		</div>
		<div class="socials">
			<a class="fb" href="https://www.facebook.com/bilimalkz">
				<div class="icon"></div>
			</a>
			<a class="vk" href="https://vk.com">
				<div class="icon"></div>
			</a>
			<a class="insta" href="https://instagram.com/bilimal?igshid=prj32v649z3h">
				<div class="icon"></div>
			</a>
		</div>
			<!-- <div class="copyright">Copyright © <script>document.write(new Date().getFullYear())</script> ITC Orleu. All rights reserved</div> -->
		</section>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App', 
  data(){
		return{
			locales:["kz","ru"],
			lang:"kz"
		}
	},
	methods:{
    contacts(){
      if(this.lang == "kz"){
        return ["Байланыстар","Жүктеу","Мүмкіндіктер","Пікірлер","Пайдалану шарттары"]
      }else{
        return ["Контакты","Скачать","Возможности","Отзывы","Условия использования"]
      }
    },
		changeLang(lang){
			this.$i18n.locale = lang;
			this.lang=lang;
			localStorage.setItem("lang",lang);
		}
	},
	mounted(){
		if(localStorage.getItem("lang")){
			this.$i18n.locale = localStorage.getItem("lang");
			this.lang=localStorage.getItem("lang");
		}else{
			this.$i18n.locale = this.lang;
		}
	}
}
</script>

<style>
</style>
